@media only screen and (max-width: 1025px) and (orientation: portrait){
    /* -- hero sec style -- */
    /* hero */
    .home{
        flex-direction: column;
        margin-left: 0;
    }
    .main .home-nav{
        margin-bottom: 0;
    }
    .home-main{
        padding-bottom: 0;
    }
    .home .home-div,
    .bible .bible-div{
        padding: 3rem 0;
        height: auto !important;
    }
    .home-left-container h1, .bible-left-container h1{
        font-size: 1.8rem;
    }
    .home-content{
        width: 100%;
        justify-content: center;
        height: 100%;
        margin-right: 0;
        padding: 2rem 0;
    }
    .home-img{
        width: 32rem;
        height: auto;
        margin-bottom: 2rem;
    }
    .home-img img{
        height: 100%;
        animation: rotate 1s ease forwards;
        margin-left: -1rem;
        opacity: 0;
        animation-delay: 0.8s;
    }
    .home-content h1{
        animation: slideBottom 1s ease forwards;
    }
    @keyframes rotate{
        0%{
            transform: rotate(-10deg);
            opacity: 90%;

        }
        50%{
            transform: rotate(10deg);
           opacity: 1;
        }
        100%{
            transform: rotate(0);
            opacity: 1;

        }
    }
    .home-left-container,
   .bible-left-container{
        font-size: 1.7rem;
    }



    /* -- faq style -- */
    .faq-wrapper{
        margin-left: 0;
    }

    .card{
        padding: 0.5rem 1rem;
        min-height: 5.5rem;
    }

    /* -- top question style --- */
    .top-question-content .card i{
        padding: 0.6rem;
        margin-right: 0.75rem;
    }


    /* contact style */
    
    .contact-content form .left, .contact-content form .right{
        height:24rem;
    }
    .contact-content form .left{
        justify-content: space-between;
    }
}

@media only screen and (max-width: 991px) {
    .home-right-container, .bible-right-container{
        width: 350px;
    }

   
    .contact-content form .left, .contact-content form .right{
        height: 22rem;
    }
}

@media only screen and (max-width: 768px) {
 

}

@media only screen and (max-width: 480px){
    .home{
        width: 100%;
       
    }
    .home .home-div,.bible .bible-div{
        flex-direction: column;
       
    }
    .home-left-container h1, .bible-left-container h1{
        text-align: center;
    }
    .home-left-container, .bible-left-container{
        width: 85%;
        padding-right: 0;
        align-items: center;
    }
    .home-left-container h1, .bible-left-container h1{
        font-size: 1.5rem;
    }
    .home-left-container p, .bible-left-container p{
        text-align: center;
        margin: 0.3rem 0;
        margin-bottom: 0.7rem;
    }
    .home-right-container, .bible-right-container{
        width: 300px;
        margin-top: 3em;
    }


    /* top question */
    .snapshot-content-div{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 14px;
    }
    .snapshot-content-div img{
       width: 100%;
    }



    /* top question */
    .top-question-content{
        grid-template-columns: repeat(1,1fr);
    }
    
    .card{
        min-height: 4rem;
    }
   
    /* faq */
    
    .faq-content .card{
        min-height:auto;
        padding: 0.5rem 0.7rem;
    }
    .accordion i{
        font-size: 1.5rem;
    }
    .accordion i:last-of-type{
        margin-left: 0.5rem;
    }
    
    .faq-content .card .panel p{
        margin-top: 0.8rem;
    }


}

@media only screen and (max-width: 360px){
    .home-left-container, .bible-left-container{
        width: 100%;
    }
    .home-left-container h1, .bible-left-container h1{
        font-size: 1.2rem;
    }

    .bible .bible-div{
        height: auto;
    }
}