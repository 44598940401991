/* @import url('../colors.css');
@import url('../variables.css');
@import url('../reset.css');
@import url('../layout.css');
@import url('../custom.css');
@import url('../components/buttons.css');
@import url('../components/accrodian.css');
@import url('../components/forms.css');
@import url('../utilities/typography.css');
@import url('../utilities/spacing.css'); */




  



/* .section-title{
    font-weight: 600;
    font-size: var(--font-size-xl);
    color: #222;
    margin-bottom: 0.6rem;
}
.section-subtitle{
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    width: 100%;
    font-weight: 500;
    font-size: var(--section-subtitle-font-size);
} */
/* Style for the custom alert */










.main-ic{
    animation: rotate 0.6s ease forwards;

 }

@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    25%{
        transform: rotate(20deg);
    }
    75%{
        transform: rotate(-20deg);
    }
    100%{
        transform: rotate(0deg);
    }
}





.chat-page-content{
    overflow-y: hidden;
}

.home,
.bible{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home .home-div,
.bible .bible-div{
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.home-left-container,
.bible-left-container{
    /* flex: 1; */
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-right: 3rem;
}
.home-left-container h1,
.bible-left-container h1{
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.1;
    animation: slideRight 1s ease forwards;
    animation-delay: .7s;
}
.home-left-container p,
.bible-left-container p{
    font-size: var(--font-size-sm);
    font-weight: 300;
    opacity: 0;
    animation: fade 1s ease forwards;
    animation-delay: 1s;
    margin: 1rem 0 1.25rem 0;
    min-height: 40px;
}

.a-home-btn{
    animation: slideTop 1s ease forwards;
    animation-delay: 400ms;
    opacity: 0;
}


.home-right-container,
.bible-right-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 380px;
    background-color:var(--main-color); ;
    position: relative;
    margin-right: -20px;
    animation-delay: 2s,3s;

}
.home-right-container img,
.bible-right-container img{
    width: 100%;
    animation: zoomIn 1s ease forwards;
}

/* animation */
@keyframes slideRight{
    0%{
        transform: translateX(-100px);
        opacity: 0;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideTop{
    0%{
        transform: translateY(100px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slideBottom{
    0%{
        transform: translateY(-100px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes fade{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes zoomIn{
    0%{
        transform: scale(0);
        opacity: 0;
    }
    100%{
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes floatImage {
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(-24px);
    }
    100%{
        transform: translateY(0);
    }
}



#snapshot,
#top-question{
    background-color: var(--color-bg-secondary);
}
/* .faq.active{
    border-radius: 1rem;
} */



  
/* .active,
.accordion:hover {
    background-color: #ccc;
} */



.snapshot-button-div{
    display: flex;
    margin-left: 0.75rem;
}
.button-div a{
    margin-left: 10px;
    padding: 0.4rem 0.6rem;
    border: none;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 0.6rem;
    font-weight: 500;
    background-color:#c9d5e5 ;
    text-decoration: none;
}
.snapshot-button-div a i{
    margin-right: 4px;
    font-size: var(--font-size-sm);
    font-size: 1.1rem;
}
.snapshot-button-div a:last-child{
    background-color:#9ac2fe ;
}
.snapshot-button-div a:hover{
    background-color: var(--color-primary-dark);
    color: white;
}
.snapshot-content-div{
    display: flex;
    justify-content: space-between;
   
}
.snapshot-content-div img{
    width: 23%;
    border-radius: var(--border-radius);
}

/* top-question */
.top-question-content{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 14px; 
}
.top-question-content a{
    text-decoration: none;
    color: var(--color-text-primary);
}
.top-question-content .card i{
    background-color: var(--color-primary-dark);
    color: var(--color-white);
    border-radius: 30px;
    padding: 0.4rem;
    margin-right: 0.5rem;
    font-size: var(--font-size-sm);
    font-weight:500;
}
.top-question-content .card:hover{
    /* box-shadow: none; */
    background-color: var(--color-primary-light);
    color: var(--color-primary);
}
.top-question-content .card p{
    font-weight: 400;
    font-size: var(--font-size-sm);
}

/* chat */
.loading-bar-container{
    position: absolute; 
    top: 50%; 
    left: 50%;  
    transform: translate(-50%, -50%);
    width: 40px; 
    height: 40px; 
    z-index: 10000;
}
.loading-bar {
    width: 100%;
    height: 100%;
    border: 4px solid #cccccc; /* Light grey border */
    border-top: 4px solid #3498db; /* Blue border for the progress */
    border-radius: 50%; /* Make it round */
    animation: spin 1s linear infinite; /* Rotation animation */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
